import { ChangeEvent, useState } from "react";
import Input from "./Input";
import { validateEmail } from "./TrialForm/DemoForm";

interface Props {
    managerName: string;
    managerEmail: string;
    participants: {
        [n: number]: {
            fullName: string;
            email: string;
        };
    };
    onChange: (participant: {
        n: number;
        fullName: string;
        email: string;
    }) => void;
}

const MINIMUM_PARTICIPANTS = 2;

const ParticipantForm = (props: Props & { n: number }) => {
    const { participants, n, onChange } = props;
    const [fullName, setFullName] = useState(participants[n]?.fullName);
    const [email, setEmail] = useState(participants[n]?.email);

    return (
        <div className="mb-4">
            <span className="sm:hidden text-primary font-bold">
                Participant {n + 1}
            </span>
            <div className="flex sm:flex-row flex-col sm:justify-between gap-2">
                <Input
                    className="w-full sm:w-1/2"
                    label="Full name"
                    value={fullName}
                    placeholder={`Full name${
                        n < MINIMUM_PARTICIPANTS ? "*" : ""
                    }`}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newFullName = e.target.value;
                        setFullName(newFullName);
                        onChange({
                            n,
                            fullName: newFullName,
                            email,
                        });
                    }}
                    validate={(fullName) =>
                        fullName.length === 0 ? "Please enter a name" : null
                    }
                />
                <Input
                    className="w-full sm:w-1/2"
                    label="Email"
                    type="email"
                    value={email}
                    placeholder={`Work email${
                        n < MINIMUM_PARTICIPANTS ? "*" : ""
                    }`}
                    validate={(email) => {
                        return validateEmail({
                            email,
                            fullName,
                            participants,
                            managerEmail: props.managerEmail,
                        });
                    }}
                    onChange={(e) => {
                        const newEmail = e.target.value;
                        setEmail(newEmail);
                        onChange({
                            n,
                            fullName,
                            email: newEmail,
                        });
                    }}
                />
            </div>
        </div>
    );
};

const InviteOthers = (props: Props) => {
    const calendarUrlPrefilled = `https://calendar.sparkwise.co/team/sparkwise/trial?name=${encodeURIComponent(
        props.managerName,
    )}&email=${encodeURIComponent(props.managerEmail)}`;

    return (
        <div className="flex flex-col gap-4">
            <h1 className="mb-5">Add participants</h1>
            <p className="text-md leading-5">
                Sparkwise is a{" "}
                <span className="font-proximabold">live group</span> experience!
                Learning is best when it's social.
            </p>
            <p className="text-md leading-5">
                You can add up to 4 participants to the session. (Don’t have a
                group? Join an{" "}
                <a className="text-blue-1" href={calendarUrlPrefilled}>
                    upcoming public session
                </a>{" "}
                instead!)
            </p>
            <div className="flex flex-col gap-2 mt-6">
                <ParticipantForm n={0} {...props} />
                <ParticipantForm n={1} {...props} />
                <ParticipantForm n={2} {...props} />
                <ParticipantForm n={3} {...props} />
            </div>
        </div>
    );
};

export default InviteOthers;
