/* eslint-disable no-useless-escape */
import { ReactNode, useEffect, useState } from "react";
import TopicSelection from "./steps/TopicSelection";
import InviteOthers from "../InviteOthers";
import ScheduleTime from "./steps/ScheduleTime";
import Loading from "./Loading";
import ErrorBox from "./ErrorBox";
import { useTRPC } from "../hooks/useTRPC";
import { useStateObject } from "../hooks/useStateObject";
import ReviewSession from "./ReviewSession";
import { Button } from "../Button";

import {
    ExclamationTriangleIcon,
    QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { DateTime } from "luxon";
import { validEmail } from "../utils";
import { useSearchParams } from "react-router-dom";
import { ProgressBar } from "../ProgressBar";

export const isWebmail = (domain: string) =>
    [
        "@gmail",
        "@outlook",
        "@yahoo",
        "@aol",
        "@hotmail",
        "@protonmail",
        "@icloud",
    ].some((testDomain) => new RegExp(testDomain).test(domain));
// TODO: These should be common
export const Tooltip = ({
    showIcon = false,
    warning,
    text,
    className,
    children,
}: {
    showIcon?: boolean;
    warning?: boolean;
    text: string;
    className?: string;
    children?: ReactNode;
}) => (
    <div className={classNames("group", className)}>
        {!showIcon ? null : warning ? (
            <ExclamationTriangleIcon className="w-5 h-5 cursor-pointer text-info-1 hover:text-info-2" />
        ) : (
            <QuestionMarkCircleIcon className="w-5 h-5 cursor-pointer text-gray-9" />
        )}
        <div className="absolute z-20 hidden max-w-xs p-2 mt-2 text-sm font-normal break-normal bg-white border rounded shadow-lg text-gray-9 border-gray-3 group-hover:block">
            {text}
        </div>
    </div>
);
// END TODO

interface Props {
    managerName: string;
    managerEmail: string;
    success: ({
        time,
        topic,
        participants,
    }: {
        time: Date;
        topic: number;
        participants: {
            fullName: string;
            email: string;
        }[];
    }) => void;
    topic: number;
    uuid: string;
}

interface State {
    error: string;
    loading: boolean;
    participants: {
        fullName: string;
        email: string;
    }[];
    step: number;
    time: Date;
    topic: number;
}

export const TOPIC_MAP = [
    {
        label: "Full session",
        title: "Communicate with Impact",
        subtitle: "Leading with the Answer",
        time: "60 min",
    },
    {
        label: "Full session",
        title: "Be a Great People Manager",
        subtitle: "Tailoring Management Style",
        time: "60 min",
    },
    {
        label: "Full session",
        title: "Gen AI Fundamentals",
        subtitle: "Intro to Generative AI at Work", 
        time: "60 min",
    }
];

export const validateEmail = ({
    fullName,
    email,
    participants,
}: {
    fullName: string;
    email: string;
    participants: {
        [n: number]: {
            fullName: string;
            email: string;
        };
    };
    managerEmail: string;
}) => {
    if (!validEmail(email)) return "Please enter a valid email address.";
    else if (isWebmail(email))
        return (
            <p className="flex-nowrap whitespace-nowrap">
                Please use your work email.{" "}
                <a href="mailto:support@sparkwise.co" className="text-blue-1">
                    Contact us
                </a>{" "}
                for assistance.
            </p>
        );
    else if (
        Object.values(participants).filter(
            (x) => x.email === email || x.fullName === fullName,
        ).length > 1
    )
        return "This participant is already added.";

    return null;
};

const DemoForm = (props: Props) => {
    const [queryParameters] = useSearchParams();
    const uuid = queryParameters.get("uuid") || "";
    const trpc = useTRPC();
    const [state, setState] = useStateObject<State>({
        error: "",
        loading: false,
        participants: [
            {
                fullName: props.managerName,
                email: props.managerEmail,
            },
        ],
        step: 0,
        time: new Date(),
        topic: props.topic,
    });

    useEffect(() => {
        let defaultDate = DateTime.now().plus({ days: 1 });
        const roundedMinutes = Math.round(defaultDate.minute / 30) * 30;

        defaultDate = defaultDate.set({
            minute: roundedMinutes,
            second: 0,
            millisecond: 0
        });
        setState({
            time: defaultDate.toJSDate(),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [dateTouched, setTouched] = useState(false);
    const { mutateAsync: createTrialSessionParticipants } =
        trpc.forms.createTrialSessionParticipants.useMutation();

    const submit = async () => {
        setState({
            error: "",
            loading: true,
        });
        createTrialSessionParticipants({
            participants: Object.values(state.participants).map((x) => ({
                email: x.email,
                firstName: x.fullName.split(" ")[0],
                lastName: x.fullName.split(" ").slice(1).join(" "),
            })),
            time: state.time,
            topic: state.topic,
            uuid,
        })
            .then(() => {
                setState({
                    loading: false,
                });
                props.success({
                    time: state.time,
                    topic: state.topic,
                    participants: state.participants,
                });
            })
            .catch((error: any) => {
                setState({
                    error: error.message,
                    loading: false,
                });
            });
    };
    const minDate = DateTime.now().plus({ days: 1 });
    const isValidDate =
        !dateTouched || DateTime.fromJSDate(state.time) >= minDate;

    const stepComponents = [
        <TopicSelection
            managerName={props.managerName}
            topic={state.topic}
            setTopic={(topic: number) => {
                setState({ topic });
            }}
        />,
        <InviteOthers
            {...props}
            {...state}
            onChange={(participant: {
                n: number;
                fullName: string;
                email: string;
            }) => {
                if (
                    participant.fullName.length > 0 ||
                    participant.email.length > 0
                )
                    setState({
                        participants: {
                            ...state.participants,
                            [participant.n]: {
                                fullName: participant.fullName,
                                email: participant.email,
                            },
                        },
                    });
            }}
        />,
        <ScheduleTime
            {...props}
            {...state}
            setTime={(time: Date) => {
                setState({ time });
            }}
            onTouched={() => setTouched(true)}
            isValidDate={isValidDate}
        />,
        <ReviewSession {...props} {...state} showHeader />,
    ];

    const validUsers = Object.values(state.participants).filter(
        (x) =>
            !!x.email &&
            !!x.fullName &&
            validateEmail({
                ...x,
                managerEmail: props.managerEmail,
                participants: state.participants,
            }) === null,
    );

    // TODO: Warnings, Conditions, etc into own data structure (Step ?)
    const stepWarnings: string[] = [
        "Please select a topic",
        "Add at least 1 more participant ",
        "Please select a time",
        "",
    ];

    const stepConditions: boolean[] = [
        state.topic !== null,
        validUsers.length >= 2,
        isValidDate,
        true,
    ];
    const [stepSeen, setStepSteen] = useState<Record<number, Boolean>>({
        0: true,
    });

    useEffect(() => {
        setStepSteen({
            ...stepSeen,
            [state.step]: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.step]);

    const { mutateAsync: trackDemoFormProgress } =
        trpc.forms.trackDemoFormProgress.useMutation();

    if (state.loading) {
        return <Loading />;
    } else if (state.error) {
        return (
            <div className="flex flex-col items-center">
                <ErrorBox error={state.error} />
                <Button
                    variant="secondary"
                    onClick={() => window.location.reload()}
                    className="w-40"
                >
                    Try again
                </Button>
            </div>
        );
    }

    const onStepChange = (step: number) => {
        setState({ step });
        uuid &&
            trackDemoFormProgress({
                email: props.managerEmail,
                step,
                topic: `${state.topic}`,
            });
    };

    return (
        <>
            <ProgressBar
                numberOfSteps={stepConditions.length}
                stepsVisited={Object.values(stepSeen).filter((x) => x).length}
                currentStep={state.step}
                moveToStep={onStepChange}
            ></ProgressBar>
            {stepComponents[state.step]}
            {state.step <= 3 && (
                <div className="flex flex-col items-center gap-0">
                    <div className="flex items-center gap-2">
                        {state.step > 0 && (
                            <Button
                                variant="tertiary"
                                disabled={state.step === 0}
                                onClick={() => {
                                    if (state.step !== 0) {
                                        setState({ step: state.step - 1 });
                                    }
                                }}
                            >
                                Back
                            </Button>
                        )}
                        <div className="flex items-center gap-0">
                            <Button
                                disabled={!stepConditions[state.step]}
                                onClick={() => {
                                    state.step === 3
                                        ? submit()
                                        : setState({ step: state.step + 1 });

                                    onStepChange(state.step + 1);
                                }}
                            >
                                {state.step === 2
                                    ? "Proceed to review"
                                    : state.step === 3
                                    ? "Confirm"
                                    : "Next"}
                            </Button>
                            {!stepConditions[state.step] && (
                                <Tooltip
                                    warning
                                    showIcon
                                    text={stepWarnings[state.step]}
                                    className="inline"
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DemoForm;
