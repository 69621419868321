import { useSearchParams } from "react-router-dom";
import { useTRPC } from "../hooks/useTRPC";
import { Button, Flex, Image, Text } from "@chakra-ui/react";
import { ProgressBar } from "../ProgressBar";
import { SESSION_DEFAULT_BACKGROUND_COLOR } from "shared/constants";
import DefaultImmersionSquare from "design/assets/DefaultImmersionSquare.png";

export const GetStartedForm = ({
    stepNumber,
    setStepNumber,
}: {
    stepNumber: number;
    setStepNumber: (step: number) => void;
}) => {
    const trpc = useTRPC();
    const [queryParameters] = useSearchParams();
    const code = queryParameters.get("code");
    const { data, error } = trpc.forms.getPurchase.useQuery({
        redemptionCode: code! || "",
    });
    if (error) {
        return <Text>{error.message}</Text>;
    }
    if (!data) {
        return <Text>Loading...</Text>;
    }
    return (
        <Flex flexDir="column" w="500px" paddingTop="60px" alignItems="center">
            <Text
                fontWeight="700"
                fontSize="32px"
                lineHeight="36px"
                fontFamily="Proxima Nova Semibold"
                marginBottom="12px"
            >
                Welcome!
            </Text>
            <Text fontSize="16px" fontWeight="400" marginBottom="40px">
                Let's get your session scheduled.
            </Text>
            <Flex
                flexDir="row"
                alignItems="center"
                marginTop="40px"
                padding="4px"
                border={"1px solid black"}
                borderRadius="12px"
                gap="20px"
                width="450px"
            >
                <Flex borderRadius={"12px"} overflow={"hidden"}>
                    <Image
                        padding="8px"
                        backgroundColor={
                            data.purchase.immersion.thumbnailBackgroundColor ||
                            SESSION_DEFAULT_BACKGROUND_COLOR
                        }
                        maxHeight="78px"
                        src={
                            data.purchase.immersion.templateUrl ||
                            DefaultImmersionSquare
                        }
                    />
                </Flex>
                <Flex flexDir="column">
                    <Text fontSize="10px" fontWeight="600" color="gray.400">
                        {data.purchase.immersion.topic.name?.toUpperCase()}
                    </Text>
                    <Text
                        fontSize="18px"
                        fontWeight="600"
                        color="gray.900"
                        fontFamily="Griff"
                    >
                        {data.purchase.immersion.tactic}
                    </Text>
                </Flex>
            </Flex>
            <Button
                colorScheme="blue"
                width="fit-content"
                marginTop="40px"
                alignSelf="center"
                onClick={() => {
                    setStepNumber(stepNumber + 1);
                }}
            >
                Set up session
            </Button>
        </Flex>
    );
};
