import NavButton from "./TrialForm/NavButton";

export const ProgressBar = ({
    numberOfSteps,
    stepsVisited,
    currentStep,
    moveToStep,
}: {
    numberOfSteps: number;
    stepsVisited: number;
    currentStep: number;
    moveToStep: (step: number) => void;
}) => {
    return (
        <nav className="mb-4">
            <ol className="flex w-12 gap-1">
                {Array(numberOfSteps)
                    .fill(0)
                    .map((_, i) => (
                        <NavButton
                            key={i}
                            className={
                                i === 0
                                    ? "rounded-l-md"
                                    : i === numberOfSteps - 1
                                    ? "rounded-r-md"
                                    : ""
                            }
                            disabled={i >= stepsVisited}
                            currentStep={currentStep}
                            step={i}
                            onClick={() => {
                                moveToStep(i);
                            }}
                        />
                    ))}
            </ol>
        </nav>
    );
};
