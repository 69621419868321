import SparkwiseSVG from "../SparkwiseSVG";
import FailureIcon from "../assets/FailureIcon.png";

const InviteExpired = () => {
    return (
        <div className="min-h-screen flex flex-col mx-auto items-center py-10 w-96">
            <SparkwiseSVG></SparkwiseSVG>
            <img className="pt-20" src={FailureIcon}></img>
            <div className="text-3xl text-purple-4 font-title font-semibold my-3 text-center">
                This invite has expired
            </div>
            <div className="text-center pt-10 font-light">
                Unfortunately, this complimentary seat is not available anymore.
            </div>
            <div className="text-center pt-10 font-light">
                If you have any questions or would like to learn more, visit{" "}
                <a href="https://www.sparkwise.co">Sparkwise</a> or email us at
                team@sparkwise.co.
            </div>
        </div>
    );
};

export default InviteExpired;
