import { Flex, Image, Link, Text } from "@chakra-ui/react";
import { Logo } from "../Logo";
import AlreadyBookedSvg from "../assets/AlreadyBooked.svg";

export const AlreadyBooked = ({ logo }: { logo: string | null }) => {
    return (
        <Flex
            flexDir="column"
            bg="white"
            h="100vh"
            w="100vw"
            alignItems="center"
        >
            <Flex
                alignItems="center"
                borderBottomColor="gray.50"
                w="100%"
                paddingX="80px"
                paddingY="8px"
                height="80px"
            >
                <Logo cobrandingLogoUrl={logo} />
            </Flex>
            <Flex flexDir="column" alignItems="center" marginTop="100px">
                <Image src={AlreadyBookedSvg} height="159px" w="159px" />
                <Text fontSize="32px" fontWeight="700" marginTop="40px">
                    You're all set!
                </Text>
                <Text
                    marginTop="12px"
                    fontSize="16px"
                    fontWeight="400"
                    color="#808080"
                >
                    This session has been redeemed.
                </Text>
                <Text
                    marginTop="40px"
                    color="dark.400"
                    fontSize="14px"
                    fontWeight={400}
                >
                    If you want to make any changes to this session, please{" "}
                    <Link
                        onClick={() =>
                            // @ts-ignore
                            window.$chatwoot.toggle("open")
                        }
                        color="#3862E3"
                    >
                        reach out to us
                    </Link>{" "}
                    for help.
                </Text>
            </Flex>
        </Flex>
    );
};
