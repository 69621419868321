import classNames from "classnames";
import { DateTime } from "luxon";
import { useMemo } from "react";
import DateTimePicker, { DateTimePickerProps } from "react-datetime-picker";

const ScheduleTime = (props: {
    setTime: (time: Date) => void;
    onTouched: () => void;
    isValidDate: boolean;
    time: Date;
}) => {
    const datePickerProps = {
        calendarIcon: null,
        clearIcon: null,
        disableClock: true,
        locale: "en-US",
        minDetail: "month",
        maxDetail: "minute",
        openWidgetsOnFocus: true,
        required: true,
        value: props.time,
    } satisfies DateTimePickerProps;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const initialTime = useMemo(() => props.time, []);


    return (
        <div>
            <h1 className="mb-7">Schedule time</h1>
            <p>
                Schedule the session at least 24 hours ahead. You can reschedule
                it later if needed!
            </p>
            <div className="flex flex-col items-center mt-8">
                <div className="flex flex-col items-center justify-center w-full gap-4 sm:flex-row sm:w-2/3">
                    <DateTimePicker
                        id="schedule-time"
                        {...datePickerProps}
                        format="MM/dd/y"
                        className={classNames(
                            "font-medium border-2 border-gray-13 rounded-md w-[120px] text-center",
                        )}
                        minDate={initialTime}
                        onChange={(t) => {
                            props.onTouched();
                            if (t) {
                                const year = DateTime.fromJSDate(t).year;
                                const month = DateTime.fromJSDate(t).month;
                                const day = DateTime.fromJSDate(t).day;
                                props.setTime(
                                    DateTime.fromJSDate(props.time)
                                        .set({
                                            year,
                                            month,
                                            day,
                                        })
                                        .toJSDate(),
                                );
                            }
                        }}
                    />

                    <select
                        title="time"
                        className={classNames(
                            "font-medium border-2 rounded-md w-[120px] text-center bg-back py-[9px]",
                            !props.isValidDate && "border-danger-0",
                            props.isValidDate && "border-gray-13",
                        )}
                        onChange={(e) => {
                            props.onTouched();
                            const timeValue = e.currentTarget.value;
                            const time = DateTime.fromFormat(
                                timeValue,
                                "h:mm a",
                            );

                            props.setTime(
                                DateTime.fromJSDate(props.time)
                                    .set({
                                        hour: time.hour,
                                        minute: time.minute,
                                    })
                                    .toJSDate(),
                            );
                        }}
                        defaultValue={DateTime.fromJSDate(initialTime).toFormat("h:mm a")}
                    >
                        {[...new Array(48)]
                            .map((_, i) => i * 30)
                            .map((minutes) => {
                                const hour = minutes / 60;
                                const minute = minutes % 60;
                                const time = DateTime.fromJSDate(props.time)
                                    .set({
                                        hour,
                                        minute,
                                    })
                                    .toFormat("h:mm a");
                                if (
                                    DateTime.now()
                                        .diff(
                                            DateTime.fromJSDate(props.time).set(
                                                {
                                                    hour: hour,
                                                    minute: minute,
                                                },
                                            ),
                                        )
                                        .as("minutes") > 0
                                )
                                    return null;

                                return <option value={time}>{time}</option>;
                            })
                            .filter((x) => !!x)}
                    </select>
                </div>
                {!props.isValidDate && (
                    <p className="mt-2 text-sm text-danger-1">
                        Select a time at least 24 hours from now.
                    </p>
                )}
                <p className="text-gray-9">
                    {Intl.DateTimeFormat().resolvedOptions().timeZone}
                </p>
            </div>
        </div>
    );
};

export default ScheduleTime;
