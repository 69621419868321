import { Button, Flex, Image, Link, Text } from "@chakra-ui/react";
import GreenSparkles from "design/assets/GreenSparkles.svg";

export const RedemptionSuccess = () => {
    return (
        <Flex
            flexDir="column"
            width="500px"
            paddingTop="60px"
            alignItems="center"
        >
            <Image src={GreenSparkles} height="72px" />
            <Text
                marginTop="32px"
                fontSize="36px"
                fontFamily="Griff"
                fontWeight="600"
            >
                Congratulations!
            </Text>
            <Text
                marginTop="20px"
                fontSize="18px"
                fontWeight="400"
                color="#606060"
                textAlign="center"
            >
                You've scheduled your session. Invites will be sent out in a few
                minutes.
            </Text>
            <Text
                fontSize="18px"
                fontWeight="400"
                marginTop="56px"
                textAlign="center"
            >
                Here's a template message you can send to participants ahead of
                the session if you'd like.
            </Text>
            <Button
                marginTop="20px"
                colorScheme="blue"
                onClick={() =>
                    window.open(
                        "https://outline.sparkwise.co/s/external-sparkwise-communication",
                        "_blank",
                    )
                }
            >
                Give me the goods!
            </Button>
            <Text
                fontSize="14px"
                color="dark.400"
                fontWeight="400"
                marginTop="56px"
            >
                If you have any questions, please{" "}
                <Link
                    onClick={() =>
                        // @ts-ignore
                        window.$chatwoot.toggle("open")
                    }
                    color="#3862E3"
                >
                    reach out to us
                </Link>
                .{" "}
            </Text>
        </Flex>
    );
};
