import ReactDOM from "react-dom/client";
import "./index.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { THEME_COLORS } from "shared/constants";
import { App } from "./App";

// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
// import { COMMIT } from "./utils";

// Sentry.init({
//     dsn: "https://444520cc7811495b9fdd65c2019f358a@o1112051.ingest.sentry.io/6617546",
//     integrations: [new BrowserTracing()],
//     release: COMMIT,
//     environment: process.env.REACT_APP_STAGE,

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 0.01,
// });

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

const theme = extendTheme({
    colors: THEME_COLORS,
});

root.render(
    <ChakraProvider theme={theme}>
        <App></App>
    </ChakraProvider>,
);
