import classNames from "classnames";
import React from "react";

interface Props {
    currentStep: number;
    onClick: () => void;
    step: number;
    className?: string;
    disabled?: boolean;
}

class NavButton extends React.Component<Props> {
    render() {
        const disabled =
            this.props.disabled || this.props.step > this.props.currentStep + 1;

        let classes = "flex h-2.5 w-5";
        if (this.props.step < this.props.currentStep)
            classes = classNames(
                classes,
                "bg-positive-1 shadow-positive-1 shadow-sm",
            );
        else if (this.props.step === this.props.currentStep)
            classes = classNames(classes, "bg-gray-7");
        else classes = classNames(classes, "bg-gray-5");

        return (
            <li className="relative md:flex md:flex-1">
                <button
                    className="group flex items-center py-0 text-sm font-medium"
                    disabled={disabled}
                    onClick={this.props.onClick}
                >
                    <span
                        className={classNames(classes, this.props.className)}
                    />
                </button>
            </li>
        );
    }
}

export default NavButton;
