import TrialForm from "./TrialForm/TrialForm";
import SessionSignupForm from "./SessionSignupForm/SessionSignupForm";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import { trpc } from "./hooks/useTRPC";
import { RedeemPurchase } from "./RedeemPurchase";
import { Chatwoot } from "./Chatwoot";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});
const trpcClient = trpc.createClient({
    links: [
        httpBatchLink({
            url: process.env.REACT_APP_API_ROOT! + "/trpc",
            fetch(url, options) {
                return fetch(url, {
                    ...options,
                    credentials: "include",
                    headers: {
                        ...options?.headers,
                    },
                });
            },
        }),
    ],
});

const TopLevelRedirect = ({ location }: { location: string }) => {
    window.location.href = location;

    return <></>;
};

export const App = () => {
    return (
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
                <Chatwoot></Chatwoot>
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/redeem"
                            element={<RedeemPurchase></RedeemPurchase>}
                        ></Route>
                        <Route path="/trial" element={<TrialForm />} />
                        <Route
                            path="/sessionsignup/:sessionParticipantId"
                            element={<SessionSignupForm />}
                        ></Route>
                        {/* Add new forms at their own paths as above */}
                        <Route
                            path="*"
                            element={
                                <TopLevelRedirect location="https://sparkwise.co" />
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </QueryClientProvider>
        </trpc.Provider>
    );
};
