import React from "react";

interface Props {
    error: string;
}

class ErrorBox extends React.Component<Props> {
    render = () => {
        return (
            <div>
                <div className="text-2xl font-bold text-gray-700">
                    We're sorry, something went wrong!
                </div>
                <p className="mt-4">
                    Please reach out to{" "}
                    <a
                        href="mailto:support@sparkwise.co"
                        className="text-blue-600"
                    >
                        support@sparkwise.co
                    </a>{" "}
                    and we'll get you set up.
                </p>
            </div>
        );
    };
}

export default ErrorBox;
