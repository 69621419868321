const ConfirmedSVG = () => (
    <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d_111_3824)">
            <path
                d="M49.5625 18.625L58 22L61.375 30.4375C61.6562 30.8594 62.0781 31 62.5 31C62.9219 31 63.2031 30.8594 63.4844 30.4375L67 22L75.2969 18.625C75.7188 18.3438 76 17.9219 76 17.5C76 17.0781 75.7188 16.7969 75.2969 16.5156L67 13L63.4844 4.70312C63.2031 4.28125 62.9219 4 62.5 4C62.0781 4 61.6562 4.28125 61.375 4.70312L58 13L49.5625 16.5156C49.1406 16.7969 49 17.0781 49 17.5C49 17.9219 49.1406 18.3438 49.5625 18.625ZM75.2969 61.5156L67 58L63.4844 49.7031C63.2031 49.2812 62.9219 49 62.5 49C62.0781 49 61.6562 49.2812 61.375 49.7031L58 58L49.5625 61.5156C49.1406 61.7969 49 62.0781 49 62.5C49 62.9219 49.1406 63.3438 49.5625 63.625L58 67L61.375 75.4375C61.6562 75.8594 62.0781 76 62.5 76C62.9219 76 63.2031 75.8594 63.4844 75.4375L67 67L75.2969 63.625C75.7188 63.3438 76 62.9219 76 62.5C76 62.0781 75.7188 61.7969 75.2969 61.5156ZM58 40C58 38.7344 57.1562 37.6094 56.0312 37.0469L41.2656 29.7344L33.9531 14.9688C32.8281 12.5781 29.0312 12.5781 27.9062 14.9688L20.5938 29.7344L5.82812 37.0469C4.70312 37.6094 4 38.7344 4 40C4 41.4062 4.70312 42.5312 5.82812 43.0938L20.5938 50.4062L27.9062 65.1719C28.4688 66.2969 29.5938 67 31 67C32.2656 67 33.3906 66.2969 33.9531 65.1719L41.2656 50.4062L56.0312 43.0938C57.1562 42.5312 58 41.4062 58 40ZM37.3281 44.9219C36.625 45.2031 36.0625 45.7656 35.7812 46.4688L31 56.1719L26.0781 46.4688C25.7969 45.7656 25.2344 45.2031 24.5312 44.9219L14.8281 40L24.5312 35.2188C25.2344 34.9375 25.7969 34.375 26.0781 33.6719L31 23.9688L35.7812 33.6719C36.0625 34.375 36.625 34.9375 37.3281 35.2188L47.0312 40L37.3281 44.9219Z"
                fill="#3AAA59"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_111_3824"
                x="0"
                y="0"
                width="80"
                height="80"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.227451 0 0 0 0 0.666667 0 0 0 0 0.34902 0 0 0 0.6 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_111_3824"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_111_3824"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);

export default ConfirmedSVG;
