import { ArrowPathIcon } from "@heroicons/react/24/solid";
import React from "react";

class Loading extends React.Component {
    render = () => {
        return (
            <div className="p-4 mt-3">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <ArrowPathIcon
                            className="h-5 w-5 text-positive-2"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="ml-3">
                        <p className="text-positive-2">Loading ...</p>
                    </div>
                </div>
            </div>
        );
    };
}

export default Loading;
