import { DateTime } from "luxon";
import { TOPIC_MAP } from "./DemoForm";

export const ReviewSession = ({
    participants,
    time,
    topic,
    showHeader = false,
}: {
    participants: any[];
    time: Date;
    topic: number;
    showHeader?: boolean;
}) => {
    const formattedDate = DateTime.fromJSDate(time).toFormat(
        "LLL dd, yyyy hh:mm a",
    );

    return (
        <div>
            {showHeader && (
                <div className="mb-8">
                    <h1 className="mb-6 text-gray-13">
                        Review scheduling details
                    </h1>
                    <p>
                        After confirming, all participants will get a calendar
                        invite and onboarding info from{" "}
                        <strong>scheduling@sparkwise.co</strong>
                    </p>
                </div>
            )}
            <div className="border p-8 rounded-md flex flex-col gap-6 shadow-xs border-b-[4px] bg-front">
                <p className="text-lg font-medium">
                    {TOPIC_MAP[topic].label}: {TOPIC_MAP[topic].title}
                </p>
                <span className="flex w-full justify-between flex-col sm:flex-row">
                    <p className="text-md text-blue--2 font-bold">Duration</p>
                    <p className="ml-2 sm:ml-4 text-gray-9">
                        {TOPIC_MAP[topic].time}utes
                    </p>
                </span>
                <span className="flex flex-col">
                    <p className="text-md text-blue--2 font-bold">
                        Participants
                    </p>
                    <ul className="flex flex-col gap-2 ml-2 sm:ml-4">
                        {Object.values(participants).map((participant) => (
                            <li key={participant.email}>
                                <span className="flex w-full gap-2 justify-between flex-col md:flex-row">
                                    <p className="text-gray-9 text-left truncate">
                                        {participant.fullName}
                                    </p>
                                    <p className="text-gray-9 text-left font-extrabold truncate">
                                        ({participant.email})
                                    </p>
                                </span>
                            </li>
                        ))}
                    </ul>
                </span>
                <span className="flex justify-between flex-col sm:flex-row">
                    <p className="text-md text-blue--2 flex-nowrap whitespace-nowrap font-bold ">
                        Date and time
                    </p>
                    <p className="text-gray-9 text-left truncate  ml-2 sm:ml-4">
                        {`${formattedDate}`}
                        <br />
                        {Intl.DateTimeFormat().resolvedOptions().timeZone}
                    </p>
                </span>
            </div>
        </div>
    );
};

export default ReviewSession;
