import { Divider, Image, Stack } from "@chakra-ui/react";
import Sparkwise from "design/assets/Sparkwise.svg";

import PowerBySparkwise from "design/assets/PowerByLogo.svg";

export const Logo = ({
    cobrandingLogoUrl,
}: {
    cobrandingLogoUrl: string | null;
}) => {
    return cobrandingLogoUrl ? (
        <Stack direction={"row"} spacing={4} h={10} alignItems={"center"}>
            <Image height="30px" src={cobrandingLogoUrl || undefined}></Image>
            <Divider orientation="vertical" h="30px"></Divider>
            <Image src={PowerBySparkwise}></Image>
        </Stack>
    ) : (
        <Image src={Sparkwise}></Image>
    );
};
