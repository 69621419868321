import SparkwiseSVG from "../SparkwiseSVG";
import Stars from "../assets/Stars.png";

const SignupConfirmation = () => {
    return (
        <div className="min-h-screen flex flex-col mx-auto items-center py-10 w-96">
            <SparkwiseSVG></SparkwiseSVG>
            <img className="pt-20" src={Stars}></img>
            <div className="text-3xl text-purple-4 font-title font-semibold my-3">
                Congrats!
            </div>
            <div className="text-center pt-10 font-light">
                You redeemed the complimentary seat. 🚀
            </div>
            <div className="text-center pt-10 font-light">
                An email and a calendar invite with the details are on their
                way. Get ready for an amazing learning experience!
            </div>
        </div>
    );
};

export default SignupConfirmation;
