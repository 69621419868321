import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { SESSION_DEFAULT_BACKGROUND_COLOR } from "shared/constants";
import DefaultImmersionSquare from "design/assets/DefaultImmersionSquare.png";
import { DateTime } from "luxon";

export const Confirmation = ({
    thumbnailBackgroundColor,
    templateUrl,
    topic,
    tactic,
    participants,
    scheduleTime,
}: {
    thumbnailBackgroundColor: string | null;
    templateUrl: string | null;
    topic: string;
    tactic: string | null;
    participants: {
        firstName: string;
        lastName: string;
        email: string;
    }[];
    scheduleTime: Date;
}) => {
    return (
        <Flex
            flexDir="row"
            alignItems="center"
            padding="8px"
            border={"1px solid black"}
            borderRadius="12px"
            gap="20px"
        >
            <Box
                display="flex"
                flexBasis="0"
                flexGrow="1"
                borderRadius={"12px"}
                maxW="45%"
                h="100%"
                minHeight="222px"
                overflow="hidden"
                backgroundColor={
                    thumbnailBackgroundColor || SESSION_DEFAULT_BACKGROUND_COLOR
                }
            >
                <Image
                    margin="auto"
                    padding="8px"
                    backgroundColor={
                        thumbnailBackgroundColor ||
                        SESSION_DEFAULT_BACKGROUND_COLOR
                    }
                    src={templateUrl || DefaultImmersionSquare}
                />
            </Box>
            <Flex flexDir="column" flexBasis="0" flexGrow="1" gap="20px">
                <Flex flexDir="column">
                    <Text fontSize="10px" fontWeight="600" color="gray.400">
                        {topic.toUpperCase()}
                    </Text>
                    <Text
                        fontSize="18px"
                        fontWeight="600"
                        color="gray.900"
                        fontFamily="Griff"
                    >
                        {tactic}
                    </Text>
                </Flex>
                <Flex flexDir="column">
                    <Text color="gray.800" fontWeight="700" fontSize="14px">
                        Participants
                    </Text>
                    <Text fontWeight="400" fontSize="14px">
                        {Object.values(participants)
                            .filter((p) => p.firstName && p.email)
                            .map((p) => `${p.firstName} ${p.lastName}`)
                            .join(", ")}
                    </Text>
                </Flex>
                <Flex flexDir="column">
                    <Text fontWeight="700" fontSize="14px" color="gray.800">
                        Date and Time
                    </Text>
                    <Text fontWeight="400" fontSize="14px">
                        {DateTime.fromJSDate(scheduleTime).toFormat(
                            "cccc, LLL dd, yyyy 'at' hh:mm a",
                        )}
                    </Text>
                    <Text fontWeight="400" fontSize="12px" color="dark.300">
                        {Intl.DateTimeFormat().resolvedOptions().timeZone}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
};
