import { useState } from "react";
import ConfirmedSVG from "../ConfirmedSVG";
import { Tooltip } from "./DemoForm";
import { Button } from "../Button";

interface Props {
    firstName: string;
    participants: {
        fullName: string;
        email: string;
    }[];
    time: Date;
    topic: number;
}

const Confirmed = (props: Props) => {
    const [isCopied, setIsCopied] = useState(false);

    return (
        <div className="flex flex-col items-center gap-6">
            <ConfirmedSVG />
            <h1 className="text-center">Congratulations!</h1>
            <p className="text-center text-dark--3">
                You've scheduled your group's session.
            </p>
            <div className="flex flex-col gap-2 items-center">
                <Button
                    variant="primary"
                    className="w-38"
                    onClick={() =>
                        window.open(
                            "https://outline.sparkwise.co/s/trial-communications",
                            "_blank",
                        )
                    }
                >
                    View a sample message to send to your team
                </Button>
            </div>
            <p className="text-center text-dark--3">
                If you have any questions, please reach out to us at{" "}
                <Button
                    className="text-primary font-medium"
                    variant="tertiary"
                    onClick={() => {
                        setIsCopied(true);
                        navigator.clipboard.writeText("support@sparkwise.co");
                    }}
                >
                    <Tooltip
                        text={
                            isCopied
                                ? "Copied to clipboard"
                                : "Copy to clipboard"
                        }
                        className="w-full absolute h-4"
                    />
                    support@sparkwise.co
                </Button>
            </p>
            <div className="flex flex-col gap-2 items-center">
                <iframe 
                    src={`https://calendar.sparkwise.co/team/sparkwise/trial-debrief?date=${props.time.toISOString().substring(0,10)}`} 
                    style={{ width: '100vw', minHeight: '500px', border: 'none' }} 
                ></iframe>
            </div> 
        </div>
    );
};

export default Confirmed;
