import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import ErrorBox from "./ErrorBox";
import Loading from "./Loading";
import DemoForm from "./DemoForm";
import Confirmed from "./Confirmed";
import { useTRPC } from "../hooks/useTRPC";
import SparkwiseSVG from "../SparkwiseSVG";
import { Button } from "../Button";

const TrialForm = () => {
    const trpc = useTRPC();
    const [queryParameters] = useSearchParams();
    const uuid = queryParameters.get("uuid");

    const { data, error } = trpc.forms.getTrialUser.useQuery({
        id: uuid! || "",
    });

    const [completedData, setCompletedData] = useState<{
        time: Date;
        topic: number;
        participants: {
            fullName: string;
            email: string;
        }[];
    }>();
    let content = <Loading />;

    if (!uuid || error) {
        return (
            <div className="flex flex-col items-center">
                <ErrorBox error={error?.message || "unknown error occurred."} />
                <Button
                    variant="secondary"
                    onClick={() => window.location.reload()}
                    className="w-40"
                >
                    Try again
                </Button>
            </div>
        );
    } else if (data) {
        if (completedData) {
            content = (
                <Confirmed
                    firstName={data.firstName || ""}
                    participants={completedData.participants}
                    time={new Date(completedData.time)}
                    topic={completedData.topic}
                />
            );
        } else {
            content = (
                <DemoForm
                    managerName={`${data.firstName} ${
                        data.lastName || ""
                    }`.trim()}
                    managerEmail={data.email}
                    success={setCompletedData}
                    topic={data.topic}
                    uuid={uuid}
                />
            );
        }
    }

    return (
        <div className="flex flex-col mx-auto items-center min-h-screen py-6 bg-back">
            <div className="md:mb-10">
                <SparkwiseSVG />
            </div>
            <div className="flex flex-col justify-between rounded-3xl mx-12 p-16 w-full md:max-w-[586px] md:w-[586px] md:min-w-[586px]">
                {content}
            </div>
        </div>
    );
};

export default TrialForm;
