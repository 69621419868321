import classNames from "classnames";
import React from "react";
import { TOPIC_MAP } from "../DemoForm";

interface Props {
    managerName: string;
    setTopic: (topic: number) => void;
    topic: number;
}

const TopicSelection = (props: Props) => {
    const TopicComponent = ({
        index,
        label,
        title,
        time,
        subtitle,
    }: {
        index: number;
        label: string;
        title: string;
        time: string;
        subtitle: string;
    }) => {
        const isChecked = props.topic === index;
        return (
            <div
                className={classNames(
                    "cursor-pointer sm:py-5 py-8 px-3 rounded-md",
                    isChecked && "bg-blue--5 border border-dark--1 border-b-4",
                )}
                key={`${index}-${label}`}
                onClick={() => props.setTopic(index)}
            >
                <div className="relative flex items-start justify-between">
                    <div className="flex h-5 items-center">
                        <input
                            type="radio"
                            checked={isChecked}
                            onChange={() => props.setTopic(index)}
                        />
                        <div className="flex flex-col ml-3 text-sm">
                            <label
                                className={classNames(
                                    "font-bold cursor-pointer text-primary",
                                )}
                            >
                                {label}
                                <span
                                    className={classNames("ml-2 text-blue--2")}
                                >
                                    ({time})
                                </span>
                            </label>
                            <span className="sm:flex flex-col sm:flex-row">
                                <p className="font-semibold">{title}:&nbsp;</p>
                                <p>{subtitle}</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div className="flex flex-col gap-8">
            <h1 className="mb-6">
                Hi {props.managerName.split(" ")?.[0]}, please confirm your
                preference
            </h1>
            <fieldset className="flex flex-col gap-2">
                {TOPIC_MAP.map((topic, index) => (
                    <TopicComponent key={index} index={index} {...topic} />
                ))}
            </fieldset>
        </div>
    );
};

export default TopicSelection;
