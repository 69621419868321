import { useReducer } from "react";

export const useStateObject = <T,>(initialState: T) =>
    useReducer(
        (state: T, newState: Partial<T>) => ({
            ...state,
            ...newState,
        }),
        initialState,
    );

export default useStateObject;
