import classNames from "classnames";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";

const Input = ({
    label,
    value,
    onChange,
    placeholder,
    disabled,
    type = "text",
    className,
    validate,
    backgroundColor = "bg-back",
}: {
    label: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    disabled?: boolean;
    type?: string;
    className?: string;
    validate?: (value: string) => ReactNode | null;
    backgroundColor?: string;
}) => {
    const [touched, setTouched] = useState(false);
    const [invalidText, setInvalidText] = useState<ReactNode | null>(null);

    useEffect(() => {
        if (!touched && !value) return;
        else if (touched && !value) return setInvalidText(null);
        else if (validate) {
            setInvalidText(validate(value));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, validate]);

    const [focused, setFocused] = useState(false);

    return (
        <div
            className={classNames(
                "relative rounded-md border px-3 py-2 shadow-sm focus-within:border-primary focus-within:ring-1 focus-within:ring-primary",
                invalidText &&
                    "ring-info-0 border-info-0  focus-within:border-info-0 focus-within:ring-1 focus-within:ring-info-0",
                className,
            )}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
        >
            {focused && value && (
                <label
                    className={classNames(
                        backgroundColor,
                        "absolute -top-2 left-1 -mt-px inline-block px-1 text-xs font-medium text-primary transition-all ease-in-out ",
                    )}
                >
                    {label}
                </label>
            )}
            <input
                className={`block w-full border-0 p-0 focus:ring-0 sm:text-sm disabled:cursor-not-allowed ${backgroundColor}`}
                value={value}
                onChange={(e) => {
                    setTouched(true);
                    onChange(e);
                }}
                disabled={disabled}
                placeholder={placeholder}
                type={type}
            />
            {invalidText && (
                <div className="absolute -bottom-4 -mt-px inline-block text-xs font-medium text-gray-9">
                    {invalidText}
                </div>
            )}
        </div>
    );
};

export default Input;
