import { DateTime } from "luxon";
import { useState, ChangeEvent, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTRPC } from "../hooks/useTRPC";
import { Button } from "../Button";
import Input from "../Input";
import GiftIcon from "../assets/GiftIcon.png";
import SparkwiseSVG from "../SparkwiseSVG";
import SignupConfirmation from "./SignupConfirmation";
import InviteExpired from "./InviteExpired";
import { ReferralSource } from "@prisma/client";

const SessionSignupForm = () => {
    const trpc = useTRPC();
    const { sessionParticipantId } = useParams();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [data, setData] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);
    const [submissionError, setSubmissionError] = useState<string | null>(null);
    const [sessionConfirmed, setSessionConfirmed] = useState(false);

    const { mutateAsync } = trpc.forms.addSessionParticipant.useMutation();
    const getSessionParticipant = trpc.forms.getSessionParticipant.useQuery({
        id: sessionParticipantId || "",
    });

    useEffect(() => {
        if (getSessionParticipant.data) {
            setData(getSessionParticipant);
        } else if (getSessionParticipant.error) {
            setError(getSessionParticipant.error.message);
        }
    }, [getSessionParticipant.data, getSessionParticipant.error]);

    if (!sessionParticipantId) {
        return <div>Session not found</div>;
    }

    const submit = () => {
        mutateAsync({
            sessionParticipantId,
            firstName: name.split(" ")[0],
            lastName: name.split(" ").slice(1).join(" "),
            email,
            referralSource: ReferralSource.RS_FREE_SEAT
        })
            .then(() => {
                setSessionConfirmed(true);
            })
            .catch((error: any) => {
                setSubmissionError(error.message);
            });
    };
    if (sessionConfirmed) {
        return <SignupConfirmation></SignupConfirmation>;
    }
    if (error) {
        return <div>{error}</div>;
    } else if (data) {
        if (
            DateTime.fromISO(data.data.sessionDate).diffNow("minutes").minutes <
            10
        ) {
            return <InviteExpired></InviteExpired>;
        }
        return (
            <div className="min-h-screen flex flex-col mx-auto items-center py-6">
                <SparkwiseSVG></SparkwiseSVG>
                <div className="border-2 h-fit flex flex-col mx-auto items-center py-12 border-dark-0 rounded-lg drop-shadow-sm mt-12 max-w-[500px] px-5 mx-5">
                    <img src={GiftIcon}></img>
                    <div className="text-3xl text-purple-4 font-title font-semibold my-3">
                        Complimentary Seat!
                    </div>
                    <div className="font-body max-w-xs text-center mb-5">
                        {data.data.firstName} {data.data.lastName} is inviting
                        you to an interactive group learning session!
                    </div>
                    <div className="m-5 p-5 outline outline-1 rounded-lg">
                        <div className="text-1xl">
                            <div className="inline font-proximabold">
                                Topic:{" "}
                            </div>
                            <div className="inline">
                                {data.data.sessionTopic} |{" "}
                                {data.data.sessionDescription}
                            </div>
                        </div>
                        <div className="text-1xl">
                            <div className="inline font-proximabold">
                                Date/Time:{" "}
                            </div>
                            {DateTime.fromISO(data.data.sessionDate)
                                .setZone(
                                    Intl.DateTimeFormat().resolvedOptions()
                                        .timeZone,
                                )
                                .toLocaleString(DateTime.DATETIME_MED)}{" "}
                            ({Intl.DateTimeFormat().resolvedOptions().timeZone})
                        </div>
                        <div className="text-1xl">
                            <div className="inline font-proximabold">
                                Duration:{" "}
                            </div>
                            1 hour
                        </div>
                        <div className="text-1xl">
                            <div className="inline font-proximabold">
                                Format:{" "}
                            </div>
                            You’ll tackle collaborative challenges in a small
                            group. Get ready to practice skills and share
                            reflections together! 🚀
                        </div>
                        <Input
                            className="w-full mt-5"
                            label="Name"
                            placeholder="Full name*"
                            value={name}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setName(e.target.value);
                            }}
                        ></Input>
                        <Input
                            className="w-full my-3"
                            label="Email"
                            placeholder="Work email*"
                            value={email}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setEmail(e.target.value);
                            }}
                        ></Input>
                        <Button
                            className="w-full"
                            type="submit"
                            onClick={submit}
                            disabled={!email || !name}
                        >
                            Get my free seat
                        </Button>
                        <div className="text-warning-0">{submissionError}</div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <div>Loading</div>;
    }
};

export default SessionSignupForm;
