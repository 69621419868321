import { DateTime } from "luxon";

export const API_SERVER_ROOT = process.env.REACT_APP_API_ROOT + "/public/forms";
// export const COMMIT = process.env.REACT_APP_COMMIT;

export const timeParser = (time: string) => {
    return new Date(time);
};

export const formatTime = (time: string | null, timeZone: string) => {
    if (!time) {
        return "";
    }
    return new Date(time).toLocaleString("en-US", {
        timeZone,
    });
};

// Convert Luxon DateTime to UTC ISO string and strip seconds and milliseconds
export const convertDateTimeToISO = (dateTime: DateTime) => {
    return dateTime.toUTC().set({ second: 0, millisecond: 0 }).toISO();
};

// Convert Luxon DateTime to a timezone and return as plain JS Date object
export const localizeTimeToJSDate = (
    datetime: DateTime,
    timezoneName: string,
) => {
    const localDateTime = datetime.setZone(timezoneName);
    return new Date(
        localDateTime.toISO({
            suppressMilliseconds: true,
            includeOffset: false,
            extendedZone: false,
        })!,
    );
};

// Convert a plain JS Date object to a Luxon DateTime with a timezone
export const convertLocalJSDateToDateTime = (
    localDatetime: Date,
    timezoneName: string,
) => {
    return DateTime.fromObject(
        {
            year: localDatetime.getFullYear(),
            month: localDatetime.getMonth() + 1,
            day: localDatetime.getDate(),
            hour: localDatetime.getHours(),
            minute: localDatetime.getMinutes(),
            second: 0,
        },
        {
            zone: timezoneName,
        },
    );
};

export const isoTimeSameMinute = (time1: string, time2: string) => {
    const date1 = new Date(time1);
    const date2 = new Date(time2);
    return (
        date1.getUTCFullYear() === date2.getUTCFullYear() &&
        date1.getUTCMonth() === date2.getUTCMonth() &&
        date1.getUTCDate() === date2.getUTCDate() &&
        date1.getUTCHours() === date2.getUTCHours() &&
        date1.getUTCMinutes() === date2.getUTCMinutes()
    );
};

// NOTE: This function is copied from packages/server/src/utils/index.ts
// If you make changes to this function, make sure to update the other copy
const domainRegex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/;
export const validDomain = (domain: string) => domainRegex.test(domain);

// https://github.com/manishsaraan/email-validator
// Modified the regex to disallow email address beginning with ['=', '+', '-', '@', '\t', '\r'] due to CSV injection
const emailRegex =
    /^[!#$%&'*\/0-9?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

// NOTE: This function is copied from packages/server/src/utils/index.ts
// If you make changes to this function, make sure to update the other copy
export const validEmail = (email: string) => {
    if (!email) return false;

    const emailParts = email.split("@");

    if (emailParts.length !== 2) return false;

    const account = emailParts[0];
    const address = emailParts[1];

    if (account.length > 64 || address.length > 255) return false;

    if (!validDomain(address)) return false;

    return emailRegex.test(email);
};
