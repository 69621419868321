import { useEffect, useState } from "react";

// Similar to Chatwoot component used by the web-app.
export const Chatwoot = () => {
    const [chatwootReady, setChatwootReady] = useState(false);

    useEffect(() => {
        // Add Chatwoot Settings
        //@ts-ignore
        window.chatwootSettings = {
            hideMessageBubble: false,
            position: "right", // This can be left or right
            locale: "en", // Language to be set
            type: "standard", // [standard, expanded_bubble]
        };
        // Code from https://support.sparkwise.co/app/accounts/1/settings/inboxes/7
        (function (doc: any, tag: any) {
            var BASE_URL = "https://support.sparkwise.co";
            var g = doc.createElement(tag),
                s = doc.getElementsByTagName(tag)[0];

            g.src = BASE_URL + "/packs/js/sdk.js";
            g.defer = true;
            g.async = true;
            s.parentNode.insertBefore(g, s);
            g.onload = function () {
                //@ts-ignore
                window.chatwootSDK.run({
                    websiteToken: "s7JQUMXEAY8ZjFtay7T9LSZm",
                    baseUrl: BASE_URL,
                });
            };
        })(document, "script");
    }, []);

    useEffect(() => {
        const setReady = () => {
            setChatwootReady(true);
        };
        window.addEventListener("chatwoot:ready", setReady);
        return () => window.removeEventListener("chatwoot:ready", setReady);
    }, [setChatwootReady]);

    useEffect(() => {
        if (chatwootReady) {
            //@ts-ignore
            window.$chatwoot.toggleBubbleVisibility("show");
        }
    }, [chatwootReady]);

    return null;
};
