import { Button, Flex, Input, Text } from "@chakra-ui/react";
import { useTRPC } from "./hooks/useTRPC";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Logo } from "./Logo";
import { GetStartedForm } from "./RedeemPurchase/GetStartedForm";
import { ProgressBar } from "./ProgressBar";
import { validEmail } from "./utils";
import { RedemptionSuccess } from "./RedeemPurchase/RedemptionSuccess";
import { InvalidCode } from "./RedeemPurchase/InvalidCode";
import { AlreadyBooked } from "./RedeemPurchase/AlreadyBooked";
import { Confirmation } from "./RedeemPurchase/Confirmation";
import DateTimePicker from "react-datetime-picker";

enum Step {
    Start = 0,
    SelectParticipants = 1,
    SelectTime = 2,
    Confirm = 3,
    Success = 4,
}

interface Participant {
    firstName: string;
    lastName: string;
    email: string;
}

export const RedeemPurchase = () => {
    const trpc = useTRPC();
    const [queryParameters] = useSearchParams();
    const code = queryParameters.get("code");
    const [stepNumber, setStepNumber] = useState(Step.Start);
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + 1, 0, 0, 0);
    const [scheduleTime, setScheduleTime] = useState(new Date(currentDate));
    const [timeError, setTimeError] = useState("");
    const [participantsError, setParticipantsError] = useState("");
    const [redemptionError, setRedemptionError] = useState("");

    useEffect(() => {
        // Clear errors when changing steps.
        setParticipantsError("");
        setRedemptionError("");
        setTimeError("");
    }, [stepNumber]);

    const validations = (participants: Participant[]) => {
        for (const [index, participant] of participants.entries()) {
            if (
                (participant.email ||
                    participant.firstName ||
                    participant.lastName) &&
                !(
                    participant.email &&
                    participant.firstName &&
                    participant.lastName
                )
            ) {
                return `Participant ${index + 1} data incomplete.`;
            }
            if (participant.email && !validEmail(participant.email)) {
                return `"${participant.email}" is not a valid email.`;
            }
        }
        if (participants.filter((p) => p.email).length < 2) {
            return "Must provide at least two participants.";
        }
    };
    const [participants, setParticipants] = useState<Participant[]>(
        Array.from({ length: 4 }, () => ({
            firstName: "",
            lastName: "",
            email: "",
        })),
    );

    const { mutateAsync: redeemCode } = trpc.forms.redeemCode.useMutation({});

    const { data, error } = trpc.forms.getPurchase.useQuery({
        redemptionCode: code! || "",
    });
    if (!code) {
        return <Text>Must specify a redemption code.</Text>;
    }
    if (error || data?.status === "DEACTIVATED") {
        return <InvalidCode></InvalidCode>;
    }
    if (!data) {
        return <Text>Loading...</Text>;
    }
    const immersion = data.purchase.immersion;
    if (data.status === "REDEEMED") {
        return (
            <AlreadyBooked
                logo={data.purchase.organization.cobrandingLogoUrl}
            ></AlreadyBooked>
        );
    }
    var formContent;
    switch (stepNumber) {
        case Step.Start:
            formContent = (
                <GetStartedForm
                    stepNumber={stepNumber}
                    setStepNumber={setStepNumber}
                />
            );
            break;
        case Step.SelectParticipants:
            formContent = (
                <Flex flexDir="column" width="500px" paddingTop="60px">
                    <ProgressBar
                        numberOfSteps={4}
                        stepsVisited={stepNumber + 1}
                        currentStep={stepNumber}
                        moveToStep={setStepNumber}
                    ></ProgressBar>
                    <Text fontWeight="700" fontSize="32px">
                        Add Participants
                    </Text>
                    <Text
                        fontSize="16px"
                        fontWeight="400"
                        color="#808080"
                        marginBottom="40px"
                    >
                        This session is a live group learning experience. You
                        can invite up to 4 participants.
                    </Text>
                    {Array(4)
                        .fill(null)
                        .map((_, index) => {
                            return (
                                <Flex
                                    flexDir="row"
                                    gap="16px"
                                    height="55px"
                                    key={index}
                                >
                                    <Flex width="225px" gap="6px">
                                        <Input
                                            placeholder={
                                                index < 2
                                                    ? "First name*"
                                                    : "First name"
                                            }
                                            value={
                                                participants[index].firstName
                                            }
                                            onChange={(e) => {
                                                setParticipants({
                                                    ...participants,
                                                    [index]: {
                                                        ...participants[index],
                                                        firstName:
                                                            e.target.value,
                                                    },
                                                });
                                            }}
                                        ></Input>
                                        <Input
                                            placeholder={
                                                index < 2
                                                    ? "Last name*"
                                                    : "Last name"
                                            }
                                            value={participants[index].lastName}
                                            onChange={(e) => {
                                                setParticipants({
                                                    ...participants,
                                                    [index]: {
                                                        ...participants[index],
                                                        lastName:
                                                            e.target.value,
                                                    },
                                                });
                                            }}
                                        ></Input>
                                    </Flex>
                                    <Flex flexDir="column" width="225px">
                                        <Input
                                            placeholder={
                                                index < 2
                                                    ? "Work email*"
                                                    : "Work email"
                                            }
                                            value={participants[index].email}
                                            onChange={(e) => {
                                                setParticipants({
                                                    ...participants,
                                                    [index]: {
                                                        ...participants[index],
                                                        email: e.target.value,
                                                    },
                                                });
                                            }}
                                        ></Input>
                                        {!validEmail(
                                            participants[index].email,
                                        ) &&
                                            participants[index].email && (
                                                <Text fontSize="sm">
                                                    Please enter a valid email
                                                    address
                                                </Text>
                                            )}
                                    </Flex>
                                </Flex>
                            );
                        })}
                    <Text
                        marginTop="10px"
                        h="30px"
                        color="danger.0"
                        alignSelf="center"
                    >
                        {participantsError}
                    </Text>
                    <Flex flexDir="row" justifyContent="center" gap="16px">
                        <Button onClick={() => setStepNumber(stepNumber - 1)}>
                            Back
                        </Button>
                        <Button
                            onClick={() => {
                                const error = validations(
                                    Object.values(participants),
                                );
                                if (error) {
                                    setParticipantsError(error);
                                } else {
                                    setStepNumber(stepNumber + 1);
                                }
                            }}
                            colorScheme="blue"
                        >
                            Next
                        </Button>
                    </Flex>
                </Flex>
            );
            break;
        case Step.SelectTime:
            formContent = (
                <Flex>
                    <Flex flexDir="column" width="500px" paddingTop="60px">
                        <ProgressBar
                            numberOfSteps={4}
                            stepsVisited={stepNumber + 1}
                            currentStep={stepNumber}
                            moveToStep={setStepNumber}
                        ></ProgressBar>
                        <Text fontWeight="700" fontSize="32px">
                            Pick a time slot
                        </Text>
                        <Text fontSize="16px" fontWeight="400" color="#808080">
                            The session will last for one hour. You can
                            reschedule it later if needed.
                        </Text>
                        <Flex
                            flexDir="column"
                            alignItems="center"
                            marginTop="30px"
                        >
                            <div className="ml-4">
                                <label
                                    htmlFor="time"
                                    className="block mb-1 text-sm font-medium text-gray-700"
                                >
                                    Timezone: (
                                    {
                                        Intl.DateTimeFormat().resolvedOptions()
                                            .timeZone
                                    }
                                    )
                                </label>
                                <DateTimePicker
                                    minDate={currentDate}
                                    className="w-48 text-sm font-medium"
                                    calendarIcon={null}
                                    clearIcon={null}
                                    disableClock={true}
                                    locale="en-US"
                                    minDetail="month"
                                    onChange={(localDatetime: Date | null) => {
                                        if (localDatetime) {
                                            // There is a weird bug here where onChange does not get called
                                            // if you set a date in the future, pick a time before the current
                                            // time, and then set today's date. In this case, the date picker
                                            // will respect minDate setting, but the onChange event will fire
                                            // only for the datetime in the past. This is the workaround.
                                            if (localDatetime < currentDate) {
                                                setScheduleTime(currentDate);
                                            } else {
                                                setScheduleTime(localDatetime);
                                            }
                                        }
                                    }}
                                    format="y/M/d h:mm a"
                                    value={scheduleTime}
                                />
                            </div>
                        </Flex>
                        <Text
                            marginTop="10px"
                            h="30px"
                            color="danger.0"
                            alignSelf="center"
                        >
                            {timeError}
                        </Text>
                        <Flex flexDir="row" justifyContent="center" gap="16px">
                            <Button
                                onClick={() => setStepNumber(stepNumber - 1)}
                            >
                                Back
                            </Button>
                            <Button
                                onClick={() => {
                                    if (scheduleTime < new Date()) {
                                        setTimeError(
                                            "Please select a time in the future.",
                                        );
                                        return;
                                    } else {
                                        setStepNumber(stepNumber + 1);
                                    }
                                }}
                                colorScheme="blue"
                            >
                                Next
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
            );
            break;
        case Step.Confirm:
            formContent = (
                <Flex
                    flexDir="column"
                    width="600px"
                    paddingTop="60px"
                    alignItems="center"
                >
                    <Flex flexDir="column" width="500px">
                        <ProgressBar
                            numberOfSteps={4}
                            stepsVisited={stepNumber + 1}
                            currentStep={stepNumber}
                            moveToStep={setStepNumber}
                        ></ProgressBar>
                        <Text fontWeight="700" fontSize="32px">
                            Review scheduling details
                        </Text>
                        <Text
                            fontSize="16px"
                            fontWeight="400"
                            color="#808080"
                            marginBottom="40px"
                        >
                            After confirming, all participants will get a
                            calendar invite and onboarding info from{" "}
                            <b>scheduling@sparkwise.co.</b>
                        </Text>
                    </Flex>
                    <Confirmation
                        thumbnailBackgroundColor={
                            immersion.thumbnailBackgroundColor
                        }
                        templateUrl={immersion.templateUrl}
                        topic={immersion.topic.name}
                        tactic={immersion.tactic}
                        participants={participants}
                        scheduleTime={scheduleTime}
                    />
                    <Text
                        marginTop="20px"
                        h="20px"
                        color="danger.0"
                        alignSelf="center"
                    >
                        {redemptionError}
                    </Text>
                    <Flex
                        flexDir="row"
                        justifyContent="center"
                        gap="16px"
                        marginTop="20px"
                    >
                        <Button onClick={() => setStepNumber(stepNumber - 1)}>
                            Back
                        </Button>
                        <Button
                            onClick={async () => {
                                try {
                                    await redeemCode({
                                        redemptionCode: code,
                                        time: scheduleTime,
                                        participants: Object.values(
                                            participants,
                                        ).filter((p) => p.email),
                                    });
                                    setStepNumber(stepNumber + 1);
                                } catch (error) {
                                    setRedemptionError((error as any).message);
                                }
                            }}
                            colorScheme="blue"
                        >
                            Submit
                        </Button>
                    </Flex>
                </Flex>
            );
            break;
        case Step.Success:
            formContent = <RedemptionSuccess></RedemptionSuccess>;
            break;
        default:
            formContent = <Text>Step not implemented</Text>;
    }
    return (
        <Flex
            flexDir="column"
            bg="white"
            h="100vh"
            w="100vw"
            alignItems="center"
        >
            <Flex
                alignItems="center"
                borderBottomColor="gray.50"
                w="100%"
                paddingX="80px"
                paddingY="8px"
                height="80px"
            >
                <Logo
                    cobrandingLogoUrl={
                        data.purchase.organization.cobrandingLogoUrl
                    }
                />
            </Flex>
            {formContent}
        </Flex>
    );
};
