import classNames from "classnames";

export const Button = ({
    onClick,
    disabled = false,
    children,
    className,
    type = "button",
    variant = "primary",
}: {
    onClick: () => void;
    disabled?: boolean;
    children: any;
    className?: string;
    type?: "button" | "submit" | "reset";
    variant?: "primary" | "secondary" | "tertiary";
}) => (
    <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={classNames(
            "my-2 rounded-md px-3 py-2.5 text-sm font-medium leading-4 shadow-sm disabled:bg-gray-6 disabled:border-none",
            variant === "primary" &&
                "bg-primary text-white border border-transparent",
            variant === "secondary" &&
                "bg-white text-primary border border-primary disabled:text-white",
            variant === "tertiary" && "bg-transparent text-gray-12",
            className,
        )}
    >
        {children}
    </button>
);
